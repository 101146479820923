import axios from 'axios';
import type {AxiosInstance, AxiosResponse, RawAxiosRequestConfig} from 'axios';
import type {ConnectionSetup, onRestError} from '@/types/rest';
import type RestConInterface from '@/api/connection/RestConInterface';

export default class ConnectionRest implements RestConInterface {
  /**
   * API host.
   * @protected
   */
  protected _host: string;
  protected _token: string | undefined;
  protected _embedded: boolean | undefined;
  protected _config: ConnectionSetup;
  readonly name = 'rest';

  protected resourcesEndpoint: string;

  protected controller: AbortController;

  readonly axios: AxiosInstance;
  readonly outAxios: AxiosInstance;

  protected errorInterceptors: Map<string, onRestError> = new Map();

  constructor(config: ConnectionSetup) {
    this._config = {...config};
    this._host = this._config.baseUrl;
    this.controller = new AbortController();
    this.axios = this.initAxios();
    this.outAxios = this.getCleanAxios();
    this.resourcesEndpoint = this._config.baseUrl.replace(/\/$/, '') + '/api/resources';
  }

  protected getAxiosConfig(): RawAxiosRequestConfig {
    return {
      mode: 'no-cors',
      withCredentials: true,
      headers: {
        'X-PAPI-SITE': location.hostname,
        'X-CSRF-Token': this._token,
      },
    } as RawAxiosRequestConfig;
  }

  protected initAxios() {
    const inst = axios.create(this.getAxiosConfig());
    inst.defaults.baseURL = this._config.baseUrl;
    return inst;
  }

  getCleanAxios() {
    return axios.create();
  }

  setEmbedded(embedded: boolean): void {
    this._embedded = embedded;
    this.axios.defaults.headers['X-PAPI-EMBEDDED'] = embedded ? '1' : '0';
  }

  setToken(token: string): void {
    this._token = token;
    this.axios.defaults.headers['X-CSRF-Token'] = token;
  }

  request<T = unknown>(params: RawAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.doRequest(this.axios, params);
  }

  outRequest<T = unknown>(params: RawAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.doRequest<T>(this.outAxios, params);
  }

  protected doRequest<T = unknown>(
    inst: AxiosInstance,
    params: RawAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    if (!params.method) {
      throw new Error('Invalid method');
    }
    if (import.meta.env.DEV) {
      const method = params.method.toUpperCase();
      console.groupCollapsed(`[API:request] ${method} ${params.url}`);
      console.info(params);
      console.trace();
      console.groupEnd();
    }
    return inst.request(params);
  }
}
